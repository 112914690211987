/*=========================================================================================
  File Name: moduleCountryMutations.js
  Description: Country Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.Nationalities.unshift(item)
  },
  SET_Nationalities(state, Nationalities) {
    debugger;
    try {
      //const parsedData = JSON.parse(countries);
      state.Nationalities = Nationalities;
      //state.OutbreakCountries =  countries;
    } catch (error) {
      console.error("Failed to parse Nationalities data JSON:", error);
      state.Nationalities = [];
    }
  },
  UPDATE_Country(state, Nationality) {
    debugger
    const NationalityIndex = state.countries.findIndex((p) => p.ID == Nationality.Id)
    Object.assign(state.Nationalities[NationalityIndex], Nationality)
  },
  REMOVE_ITEM(state, itemId) {
      const ItemIndex = state.Nationalities.findIndex((p) => p.ID == itemId)
      state.Nationalities.splice(ItemIndex, 1)
  },
}
