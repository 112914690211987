ct<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">

    <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />

    <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="countries">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <vs-button v-if="checkPermission(اضافه) || CheckUser()" type="border" icon-pack="feather" icon="icon-plus" @click="addNewData">{{$t("AddNew")}}</vs-button>

        <!-- <vs-button type="border" icon-pack="feather" icon="icon-download" @click="ExcelExport">{{$t("Export") }}</vs-button> -->

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ countries.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : countries.length }} of {{ countries.length }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>

          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage=4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>

        </vs-dropdown>

      </div>

      <template slot="thead">
        <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{$t("NameAr")}}</vs-th>
       
        <vs-th style="font-size: 16px;font-family:almarai ; color: crimson;">{{$t('Action')}}</vs-th>
      </template>

        <template slot-scope="{data}">
          <tbody>

            <tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" @click="editData(tr)">

              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.NameAR }}</p>
              </vs-td>
<!-- 
              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.CodeNumber }}</p>
              </vs-td> -->

              <!-- <vs-td>
                <p class="product-name font-medium truncate">{{ tr.FormatPhone }}</p>
              </vs-td> -->

              <vs-td class="whitespace-no-wrap">
                <feather-icon v-if="checkPermission(تعديل) || CheckUser()" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="editData(tr)" />
                <feather-icon v-if="checkPermission(حذف) || CheckUser()" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="openConfirm(tr)" />
              </vs-td>

            </tr>
          </tbody>
        </template>
    </vs-table>
  </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js";
import DataViewSidebar from "./DataViewSidebar.vue";
import moduleNationality from "@/store/Nationality/moduleNationality.js";
// import axios from "@/axios.js";

export default {
  components: {
    DataViewSidebar
  },
  data() {
    return {
      //countries: [],
      itemsPerPage: 10,
      isMounted: false,
      baseURL: domain,
      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
      rowDataForDelete: {},
      activeConfirm: false
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    countries() {
     debugger;
       return this.$store.state.NationalityList.Nationalities;
   },
  },
  methods: {
    addNewData() {
      debugger;
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
    CheckUser() {
      debugger;
          if (typeof localStorage !== 'undefined') {
            const userInfo = localStorage.getItem("userInfo");
            if (userInfo) {
              return JSON.parse(userInfo).UserType === "SuperAdmin";
            }
          }
          return false; // Default return if localStorage or userInfo is not available
        },
      checkPermission(OPName) {
    const screenRolesPermissions = JSON.parse(localStorage.getItem('ScreenRolesPermissions')) || [];
    
    // Ensure Page and Page.Name are not null or undefined
    return screenRolesPermissions.some(permission => 
      permission.Operation && permission.Operation.Name === OPName
    );
  },
    deleteData(data) {
      this.$store.dispatch("NationalityList/removeItem", data).then(() => {
        this.$store.dispatch("NationalityList/fetchDataListItems");
        })
        .catch((err) => {
          console.error(err);
          this.$store.dispatch("NationalityList/fetchDataListItems");
        });
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.sidebarData = data;
      this.toggleDataSidebar(true);
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title:"Confirm",
        text: "Are you sure you want to delete this record ?",
        accept: this.acceptAlert
      });
    },
    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({ time:8000,
        color: "success",
        title: "Deleted record",
        text: "Successfully Deleted",
      });
      //this.GetCountries();
    },
    // ExcelExport() {
    //   debugger;
    //   const data = {
    //     tableName: 'Country',
    //     fileName: 'CountryExport',
    //     SheetName: 'Countries'
    //   };
    //   axios
    //     .post("/Api/Common/ExportFileExcel", data)
    //     .then(({ data }) => {
    //       debugger;
    //       this.downloadExcelExport();
    //     })
    //     .catch((error) => console.error(error));
    // },
    // downloadExcelExport() {
    //   const fileName = 'CountryExport.xlsx';

    //   axios
    //     // .get("/Uploads/CountryExport.xlsx", { responseType: "blob" })
    //     .get("/Uploads/" + fileName, { responseType: "blob" })
    //     .then(({ data }) => {
    //       const blob = new Blob([data], { type: "application/pdf" });
    //       const link = document.createElement("a");
    //       link.href = URL.createObjectURL(blob);
    //       // link.download = "CourseExport.xlsx";
    //       link.download = fileName;
    //       link.click();
    //       URL.revokeObjectURL(link.href);
    //     })
    //     .catch((error) => console.error(error));
    // },
  },
  created() {
    if (!moduleNationality.isRegistered) {
      this.$store.registerModule("NationalityList", moduleNationality);
      moduleNationality.isRegistered = true;
    }
    debugger;
    this.$store.dispatch("NationalityList/fetchDataListItems");
    //this.GetCountries();

  },
  mounted() {
    this.isMounted = true;
    //this.GetCountries();
  }
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
